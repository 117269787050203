// import Swiper from 'swiper/bundle';

new Swiper('#features_swiper', {
	slidesPerView: 1,
	slidesPerGroup: 1,
	loop: true,
	spaceBetween: 0,
	speed: 800,
	pauseOnMouseEnter: true,
   observer: true,
   keyboard: {
      enabled: true,
      onlyInViewport: false,
   },
	autoplay: {
		delay: 600,
		disableOnInteraction: false,
	},
	effect: 'fade',
	fadeEffect: {
		crossFade: true
	},
})
// import Swiper from 'swiper/bundle';

new Swiper('#reviews_swiper', {
   slidesPerGroup: 1,
   loop: false,
   centeredSlides: false,
   initialSlide: 1,
   spaceBetween: 20,
   speed: 300,
   grabCursor: true,
   keyboard: {
      enabled: true,
      onlyInViewport: false,
   },
   pagination: {
      el: '.swiper-pagination',
      clickable: true,
      type: 'bullets',
      dynamicBullets: true
   },
   breakpoints: {
      120: {
         slidesPerView: 1.25,
         slidesPerGroup: 1,
         spaceBetween: 10,
         centeredSlides: true
      },
      700: {
         slidesPerView: 2,
         spaceBetween: 20,
         slidesPerGroup: 1,
         centeredSlides: true
      },
      1000: {
         slidesPerView: 2.3,
         spaceBetween: 20,
         slidesPerGroup: 1,
         // centeredSlides: true
      },
      1300: {
         slidesPerView: 3,
         spaceBetween: 20,
         slidesPerGroup: 1,
         centeredSlides: false,
      }
   }
})